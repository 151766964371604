import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const TripSummary = ({ module }) => {
  return (
    <>
      <section className="team-info lg:py-60 py-30">
        <div className="container-fluid-md">
          <div className="bg-white lg:p-100 p-50">
            <div className="flex flex-wrap items-center">
              <div className="lg:w-4/12 w-full">
                <div className="left-content lg:pr-100 laptop:pr-60 lgscreen:pr-0">
                  {module.tripSummary.map((menu, i) => {
                    return (
                      <div className="content-block border-b-1 border-gray-1000 border-opacity-80 lg:py-25 py-15">
                        <span className="text-13 text-gray-500 font-400 uppercase font-preheaders">
                          {menu.preheading}
                        </span>
                        <h6 className=" text-28 mt-5">{menu.heading}</h6>
                        <span className="text-11 font-400 text-dark uppercase">
                          {menu.subheading}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="lg:w-8/12 w-full">
                <div className="right-content text-center m-auto lg:pl-250 relative xlscreen:pl-200 lgscreen:pl-0 lg:pt-0 pt-50">
                  <div className="img flex justify-center">
                    <img
                      src={module.consultantImage.mediaItemUrl}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  {module.consultantText && (
                    <div className="content py-30">
                      {parse(module.consultantText)}
                    </div>
                  )}
                  <div className="btn-custom flex flex-col items-center gap-y-6">
                    <Link
                      to={module.cta.url}
                      target={module.cta.target}
                      className="button button-transparent"
                    >
                      {module.cta.title}
                    </Link>
                    <Link to={module.additionalCta.url} className="link-btn">
                      {module.additionalCta.title}
                    </Link>
                  </div>
                  {module.content && (
                    <span className="price-text">{parse(module.content)}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TripSummary;
export const TripSummaryFragment = graphql`
  fragment TripSummaryFragment on WpItinerary_Itinerarycontent_TripContent_TripSummary {
    # content
    consultantText
    fieldGroupName
    cta {
      url
      title
      target
    }
    tripSummary {
      subheading
      preheading
      heading
      fieldGroupName
    }
    content
    consultantImage {
      altText
      mediaItemUrl
      uri
      title
    }
    additionalCta {
      url
      title
      target
    }
  }
`;
