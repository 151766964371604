import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ItineraryImageContentWithTab = ({ module }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const [tabOption, setTabOption] = useState(
    module.tabs ? module.tabs[0].tabTitle : ''
  );

  const onTabChange = (item, index) => {
    setTabOption(item.tabTitle);
  };

  const onSlideChange = (e) => {
    setTabOption(module.tabs[e.realIndex]?.tabTitle);
  };

  return (
    <>
      <section
        className={`zigzag lg:pb-20 pb-20 zigzag-tabs lg:pt-20 pt-20 ${module.extraClass}`}
      >
        <div className="container-fluid">
          <div className=" ">
            <div className="flex flex-wrap">
              <div
                className={`lg:w-6/12 w-full lg:!h-[600px] relative ${
                  'Right' === module.imagePosition ? ' lg:order-1' : ''
                }`}
              >
                <Swiper
                  className="contactSlider lg:!h-[600px]"
                  loop={false}
                  navigation={true}
                  modules={[Navigation, Pagination]}
                  pagination={{
                    type: 'progressbar',
                  }}
                >
                  {module.gallery?.length > 0 &&
                    module.gallery.map((item, i) => {
                      return (
                        <div key={i} className="">
                          <SwiperSlide>
                            <img
                              src={item?.imgixImage.fluid.srcWebp}
                              alt={item?.tabImage?.altText}
                              className="h-[600px] mdscreen:h-[250px] object-cover"
                              width="910"
                              height="620"
                              loading="lazy"
                            />
                          </SwiperSlide>
                        </div>
                      );
                    })}
                </Swiper>

                {module?.fullVideo && module?.fullVideo !== '' && (
                  <div className="inline-block cursor-pointer mt-15 absolute top-50-per left-50-per translate-x-minus_50 translate-y-minus_50">
                    <button
                      onClick={() => {
                        openModal();
                      }}
                      className="inline-block"
                    ></button>
                  </div>
                )}
              </div>
              <div className="lg:w-6/12 w-full flex items-center">
                <div className="zigzag-content mdscreen:!w-full !py-0 mdscreen:!py-30 lg:text-left text-center fade-ani">
                  {module?.preheading && (
                    <span className="w-full inline-block uppercase font-preheaders">
                      {module.preheading}
                    </span>
                  )}
                  <div className="title-black">
                    <h2>{module.heading}</h2>
                  </div>
                  {module.subheading && (
                    <span className="text-16 text-dark font-400 uppercase leading-22 mb-10 block">
                      {module?.subheading}
                    </span>
                  )}
                  <div className="zigzag-tab max-w-600 lgscreen:m-auto">
                    <ul className="tabs mt-20 mb-20 inline-block">
                      {module.tabs &&
                        module.tabs.map((item, i) => {
                          return (
                            <li
                              onClick={() => onTabChange(item, i)}
                              className={`tab-link cursor-pointer text-dark uppercase font-400 text-12 font-preheaders ${
                                tabOption == item.tabTitle ? 'tab-current' : ''
                              }`}
                            >
                              {item.tabTitle && parse(item.tabTitle)}
                            </li>
                          );
                        })}
                    </ul>
                  </div>

                  {module.preheading && (
                    <div className="zigzag-tabs-row">
                      <span className="w-full inline-block uppercase font-preheaders">
                        {module.preheading}
                      </span>
                    </div>
                  )}
                  {module.tabs &&
                    module.tabs.map((item, i) => {
                      return (
                        item.tabTitle === tabOption && (
                          <div
                            id="overview"
                            className="tab-content global-list"
                          >
                            {(item?.heading || item?.subHeading) && (
                              <div className="title-black mb-20 lg:pr-100">
                                <h4>{item.heading}</h4>
                                <span className="btm-text">
                                  {item.subheading}
                                </span>
                              </div>
                            )}

                            {item.content && (
                              <div className="content">
                                {parse(item.content)}
                              </div>
                            )}
                            <div className="btn-custom lg:mt-25 mt-15">
                              {module.cta && (
                                <Link
                                  to={module.cta.url}
                                  className="button button-transparent"
                                >
                                  {module.cta.title}
                                </Link>
                              )}
                            </div>
                            {item.socialIcons && (
                              <div className="flex pt-20 gap-x-3 items-center lgscreen:justify-center">
                                {item.socialIcons.map((item1, i) => {
                                  return (
                                    <div className="s-icon">
                                      <a href={item1.socialLink}>
                                        <img
                                          src={item1.socialIcon.mediaItemUrl}
                                        />
                                      </a>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItineraryImageContentWithTab;

export const ItineraryImageContentWithTabFragment = graphql`
  fragment ItineraryImageContentWithTabFragment on WpItinerary_Itinerarycontent_TripContent_ItineraryImageContentWithTab {
    # content
    fieldGroupName
    heading
    tabs {
      tabTitle
      subheading
      heading
      fieldGroupName
      content
    }
    subheading
    preheading
    imagePosition
    gallery {
      altText
      imgixImage {
        fluid(
          maxWidth: 910
          maxHeight: 620
          imgixParams: { w: 910, h: 620, fit: "crop" }
        ) {
          srcWebp
        }
      }
      uri
      title
    }
    cta {
      url
      title
      target
    }
  }
`;
