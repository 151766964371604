import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ItineraryLoop = ({ module }) => {
  const [currentData, setCurrentData] = useState('');

  useEffect(() => {
    setCurrentData(window.location.pathname);
  }, []);

  const itinerary = useStaticQuery(graphql/* GraphQL */ `
    query WpItineraryData {
      allWpItinerary {
        edges {
          node {
            itineraryContent {
              tripContent {
                ... on WpItinerary_Itinerarycontent_TripContent_IntroContent {
                  description
                  fieldGroupName
                  subheading
                  preheading
                  heading
                  cta {
                    url
                    title
                    target
                  }
                  additionalCta {
                    url
                    title
                    target
                  }
                }
              }
            }
            content
            uri
            title
            featuredImage {
              node {
                altText
                uri
                title
                imgixImage {
                  fluid(
                    maxWidth: 796
                    maxHeight: 517
                    imgixParams: { w: 796, h: 517, fit: "crop" }
                  ) {
                    srcWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const data = itinerary.allWpItinerary.edges;
  const handleNextClickStay = () => {
    const swiper = document.querySelector('.imgBox-slider .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.imgBox-slider .swiper').swiper;
    swiper.slidePrev();
  };
  return (
    <>
      <section className="imgBox-slider lg:py-60 py-30 ext-pb-120">
        <div className="container-fluid-md pr-0 mdscreen:pl-0">
          <div className="title-black pb-60 mdscreen:pl-20 mdscreen:pb-30">
            <h3>{module.heading}</h3>
          </div>
          <div className="slider-wrapper relative">
            <Swiper
              loop={true}
              slidesPerView={2.2}
              speed={1000}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                  allowTouchMove: true,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2.2,
                  spaceBetween: 40,
                },
              }}
            >
              {data.map((item, i) => {
                return (
                  item.node.uri != currentData && (
                    <SwiperSlide>
                      <div className="card-bx" key={i}>
                        <div className="relative">
                          <img
                            src={
                              item.node.featuredImage.node.imgixImage.fluid
                                .srcWebp
                            }
                            alt={item.node.featuredImage.node.altText}
                            width="796"
                            height="517"
                            loading="lazy"
                          />
                        </div>
                        <div className="img-content pt-30 smscreen2:text-center mdscreen:pl-20">
                          <Link to={item.node.uri}>
                            <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                              {
                                item.node.itineraryContent.tripContent[0]
                                  .preheading
                              }
                            </span>
                          </Link>
                          <h4 className="text-black-200">
                            <Link to={item.node.uri}>
                              {
                                item.node.itineraryContent.tripContent[0]
                                  .heading
                              }
                            </Link>
                          </h4>
                          <span className="uppercase text-dark text-11 font-400 mt-15 inline-block font-preheaders">
                            <Link to={item.node.uri}>
                              {
                                item.node.itineraryContent.tripContent[0]
                                  .subheading
                              }
                            </Link>
                          </span>
                          <Link
                            to={item.node.uri}
                            className="link-btn cursor-pointer"
                          >
                            View Itinerary
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                );
              })}
              <div className="swiper-arrow z-9 flex items-center justify-between w-full top-35-per translate-y-minus_50 px-20">
                <a
                  className="button-prev cursor-pointer"
                  onClick={handlePrevClickStay}
                ></a>
                <a
                  className="button-next cursor-pointer"
                  onClick={handleNextClickStay}
                ></a>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItineraryLoop;
export const ItineraryLoopFragment = graphql`
  fragment ItineraryLoopFragment on WpItinerary_Itinerarycontent_TripContent_ItineraryLoop {
    # content
    fieldGroupName
    heading
  }
`;
