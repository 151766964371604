import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

const FullWidthBanner = ({ module }) => {
  const backgroundType = module.backgroundType;
  const videoUrl = module.videoOptions.backgroundVideo;
  const imageFallback = module.videoOptions.fallbackImage;
  const mobileFallback =
    module.videoOptions.fallbackImage?.imgixImage?.fluid.srcWebp;
  const [background, setBackground] = useState(
    imageFallback?.imgixImage.url || ''
  );
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const video = document.getElementById('hero-video');
    if (video) {
      video.onloadeddata = () => {
        setVideoLoaded(true);
      };
    }
  }, [videoLoaded]);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setMobileView(true);
    }
  }, [mobileView]);

  useEffect(() => {
    const heroImageUrl = mobileFallback;
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = heroImageUrl;
    head.appendChild(link);
  }, [mobileFallback]);

  return (
    <>
      <section
        className={`banner overflow-hidden ${
          module.extraClass ? module.extraClass : ''
        }`}
      >
        <div className="relative mdscreen:px-0">
          {backgroundType && backgroundType === 'Video Background' ? (
            <video
              id="hero-video"
              poster={mobileFallback}
              playsInline
              autoPlay
              muted
              loop
              onError={() => {
                console.log('video error');
              }}
            >
              {!videoLoaded && <source src={videoUrl} type="video/mp4" />}
              <track kind="captions"></track>
            </video>
          ) : module.imageOptions &&
            module.extraClass !== 'inner-banner full-width-banner-map' ? (
            <img
              src={
                module?.imageOptions?.backgroundImage.imgixImage.fluid.srcWebp
              }
              loading="eager"
              alt=""
              className="w-full h-screen object-cover"
            />
          ) : (
            <img
              src={
                module?.imageOptions?.backgroundImage.imgixImage.fluid.srcWebp
              }
              loading="lazy"
              alt=""
              className="w-full h-screen object-contain"
            />
          )}
          <div className="banner-content absolute top-50-per translate-y-minus_50 text-center w-full fade-ani">
            <h1 className="text-white">{module.heading}</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullWidthBanner;
export const FullWidthBannerFragment = graphql`
  fragment FullWidthBannerFragment on WpPage_Flexiblecontent_FlexibleContent_FullWidthBanner {
    # content
    backgroundType
    fieldGroupName
    extraClass
    imageOptions {
      backgroundImage {
        altText
        imgixImage {
          fluid(maxWidth: 1600, imgixParams: { w: 1600, h: 758, fit: "crop" }) {
            srcWebp
          }
        }
        mediaItemUrl
        title
      }
    }
    videoOptions {
      fullVideo
      fieldGroupName
      fallbackImage {
        altText
        imgixImage {
          url(imgixParams: { w: 1600 })
          fluid(maxWidth: 767) {
            srcWebp
          }
        }
        mediaItemUrl
        title
      }
      backgroundVideo
    }

    heading
  }
`;
export const BlogFullWidthBannerFragment = graphql`
  fragment BlogFullWidthBannerFragment on WpPost_Blogcontent_BlogContent_FullWidthBanner {
    # content
    backgroundType
    extraClass
    videoOptions {
      fullVideo
      fieldGroupName
      fallbackImage {
        altText
        uri
        title
        imgixImage {
          url(imgixParams: { w: 1600 })
          fluid(maxWidth: 767) {
            srcWebp
          }
        }
      }
      backgroundVideo
    }
    imageOptions {
      backgroundImage {
        altText
        uri
        mediaItemUrl
        imgixImage {
          fluid(maxWidth: 1600, imgixParams: { w: 1600, h: 758, fit: "crop" }) {
            srcWebp
          }
        }
        title
      }
    }
    heading
    fieldGroupName
  }
`;
export const FullWidthBannerPropertyFragment = graphql`
  fragment FullWidthBannerPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_FullWidthBanner {
    # content
    backgroundType
    extraClass
    videoOptions {
      fullVideo
      fieldGroupName
      fallbackImage {
        altText
        uri
        mediaItemUrl
        title
        imgixImage {
          url(imgixParams: { w: 1600 })
          fluid(maxWidth: 1600) {
            srcWebp
          }
        }
      }
      backgroundVideo
    }
    imageOptions {
      backgroundImage {
        altText
        uri
        mediaItemUrl
        imgixImage {
          fluid(maxWidth: 1600, imgixParams: { w: 1600, h: 758, fit: "crop" }) {
            srcWebp
          }
        }
        title
      }
    }
    heading
    fieldGroupName
  }
`;
export const ItineraryFullWidthBannerFragment = graphql`
  fragment ItineraryFullWidthBannerFragment on WpItinerary_Itinerarycontent_TripContent_FullWidthBanner {
    # content
    backgroundType
    extraClass
    videoOptions {
      fullVideo
      fieldGroupName
      fallbackImage {
        altText
        imgixImage {
          url(imgixParams: { w: 1600 })
          fluid(maxWidth: 767) {
            srcWebp
          }
        }
        uri
        title
      }
      backgroundVideo
    }
    imageOptions {
      backgroundImage {
        mediaItemUrl
        imgixImage {
          fluid(maxWidth: 1600, imgixParams: { w: 1600, h: 758, fit: "crop" }) {
            srcWebp
          }
        }
        altText
        uri
        title
      }
    }
    heading
    fieldGroupName
  }
`;
