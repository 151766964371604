import { graphql } from 'gatsby';
import React from 'react';
import FullWidthBanner from '../components/FullWidthBanner';
import IntroContent from '../components/IntroContent';
import ItineraryImageContentWithTab from '../components/ItineraryImageContentWithTab';
import ItineraryLoop from '../components/ItineraryLoop';
import RelatedCards from '../components/RelatedCards';
import TripSummary from '../components/TripSummary';

const ItineraryModules = (props) => {
  const modules = props.modules.tripContent;
  const { location } = props;
  const components = {
    IntroContent,
    FullWidthBanner,
    TripSummary,
    ItineraryLoop,
    RelatedCards,
    ItineraryImageContentWithTab,
  };
  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpItinerary_Itinerarycontent_TripContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                i,
                postData: props.postData,
              })
            );
          })
        : null}
    </>
  );
};

export default ItineraryModules;

export const ItineraryModulesFragment = graphql`
  fragment ItineraryModulesFragment on WpItinerary {
    itineraryContent {
      preheading
      tripContent {
        __typename
        ...ItineraryIntroContentFragment
        ...ItineraryFullWidthBannerFragment
        ...TripSummaryFragment
        ...ItineraryLoopFragment
        ...ItineraryRelatedCardsFragment
        ...ItineraryImageContentWithTabFragment
      }
    }
  }
`;
